import React from 'react';
import { Link } from 'react-router-dom';
import { FaUser, FaTh, FaBox, FaTwitch, FaStore, FaSearch } from 'react-icons/fa'; // Import icons from react-icons
import { BiGridAlt } from 'react-icons/bi';






const BottomNavbar = () => {
  return (
    <div style={styles.bottomNavbar}>
      <Link to="/" style={styles.link}>
        <div style={styles.iconContainer}>
          <FaStore size={30} color="white" />
          <span style={styles.label}>Stores</span>
        </div>
      </Link>

      {/* Categories Icon
      <Link to="/categories" style={styles.link}>
        <div style={styles.iconContainer}>
          <BiGridAlt size={30} color="white" />
          <span style={styles.label}>Categories</span>
        </div>
      </Link> */}

      <Link to="/myorders" style={styles.link}>
        <div style={styles.iconContainer}>
          <FaBox size={30} color="white" />
          <span style={styles.label}>Orders</span> 
        </div>
      </Link>

      {/* <Link to="/feed" style={styles.link}>
        <div style={styles.iconContainer}>
          <FaTwitch size={30} color="white" />
          <span style={styles.label}>Explore</span> 
        </div>
      </Link> */}

      <Link to="/menu" style={styles.link}>
        <div style={styles.iconContainer}>
          <FaTh size={30} color="white" />
          <span style={styles.label}>Menu</span>
        </div>
      </Link>

      <Link to="/profile" style={styles.link}>
        <div style={styles.iconContainer}>
          <FaUser size={30} color="white" />
          <span style={styles.label}>Profile</span>
        </div>
      </Link>
    </div>
  );
};

const styles = {
  bottomNavbar: {
    width: '100%',
    height: '60px',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 1000,
  },
  link: {
    padding: '10px',
    textDecoration: 'none', // No underline
    color: '#EEEEEE', // Icon color
    textAlign: 'center', // Center align the text below icons
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column', // Stack icon and label vertically
    alignItems: 'center', // Center the icon and label
  },
  label: {
    fontSize: '12px', // Small font size for labels
    color: 'white', // Label color
    marginTop: '4px', // Space between icon and label
  },
};

export default BottomNavbar;
