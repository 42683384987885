import React, { useState, useEffect } from 'react';

const AddRemoveFee = () => {
  const [deliveryFee, setDeliveryFee] = useState('');
  const [additionalFees, setAdditionalFees] = useState([{ id: Date.now(), name: '', amount: '' }]);
  const [savedFees, setSavedFees] = useState([]);
  const [storeName, setStoreName] = useState(''); // State for store name
  const userEmail = localStorage.getItem('userEmail');

  useEffect(() => {
    const fetchFees = async () => {
      try {
        // const response = await fetch(`http://192.168.29.80:5001/fetch-fees?user_email=${userEmail}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/fetch-fees?user_email=${userEmail}`);
        const data = await response.json();
        if (response.ok) {
          setStoreName(data.store_name); // Set store name from response
          setSavedFees(data.fees);
        } else {
          console.error(data.error || 'Failed to fetch fees');
        }
      } catch (error) {
        console.error('Error fetching fees:', error);
      }
    };

    fetchFees();
  }, [userEmail]);

  const handleDeliveryFeeChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^[0-9]+\.?[0-9]*$/.test(value)) {
      setDeliveryFee(value);
    }
  };

  const handleAdditionalFeeChange = (id, field, value) => {
    setAdditionalFees((prevFees) =>
      prevFees.map((fee) => (fee.id === id ? { ...fee, [field]: value } : fee))
    );
  };

  const addFeeField = () => {
    setAdditionalFees([...additionalFees, { id: Date.now(), name: '', amount: '' }]);
  };

  const removeFeeField = (id) => {
    setAdditionalFees(additionalFees.filter((fee) => fee.id !== id));
  };

  const handleSave = async () => {
    const validAdditionalFees = additionalFees.filter(fee => fee.name && fee.amount);
    const validFees = validAdditionalFees.map(fee => ({
      feename: fee.name,
      feevalue: fee.amount,
    }));

    if (deliveryFee) {
      validFees.push({ feename: 'Delivery Fee', feevalue: deliveryFee });
    }

    if (validFees.length === 0 || !storeName) {
      alert('Please add at least one valid fee and store name.');
      return;
    }

    try {
      // const response = await fetch('http://192.168.29.80:5001/add-fee', 
        const response = await fetch('https://cosmyk.uc.r.appspot.com/add-fee',
          {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_email: userEmail,
          store_name: storeName, // Include store name in the request body
          fee: validFees,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        alert(data.message);
        setSavedFees((prevFees) => [...prevFees, ...validFees]);
        setAdditionalFees([{ id: Date.now(), name: '', amount: '' }]);
        setDeliveryFee('');
        // Reset store name if needed, otherwise comment this line
        // setStoreName(''); 
      } else {
        alert(data.error || 'Failed to save fees');
      }
    } catch (error) {
      console.error('Error saving fees:', error);
    }
  };

  const handleDelete = async () => {
    const isConfirmed = window.confirm("Are you sure you want to delete all fees?");
    
    if (isConfirmed) {
      try {
        // const response = await fetch('http://192.168.29.80:5001/delete-fees',
          const response = await fetch('https://cosmyk.uc.r.appspot.com/delete-fees',
             {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_email: userEmail }),
        });

        const data = await response.json();
        if (response.ok) {
          alert(data.message || 'All fees deleted successfully.');
          setSavedFees([]);
          setStoreName(''); // Reset store name after deletion
        } else {
          alert(data.error || 'Failed to delete fees');
        }
      } catch (error) {
        console.error('Error deleting fees:', error);
      }
    }
  };

  return (
    <div style={styles.container}>
      <h6 style={styles.heading}>Add Fee</h6>

      {!savedFees.length ? (
        <>
          <div style={styles.card}>
            <label style={styles.label}>Store Name:</label>
            <input 
              type="text" 
              placeholder="Enter Store Name" 
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
              style={styles.input} 
            />
            <label style={styles.label}>Delivery Fee:</label>
            <input 
              type="number" 
              placeholder="Enter Delivery Fee" 
              value={deliveryFee}
              onChange={handleDeliveryFeeChange}
              style={styles.input} 
            />
          </div>

          <h6 style={styles.subHeading}>Additional Services</h6>
          {additionalFees.map((fee) => (
            <div key={fee.id} style={styles.additionalFeeContainer}>
              <label style={styles.label}>Service Name:</label>
              <input 
                type="text" 
                placeholder="Service Name" 
                value={fee.name}
                onChange={(e) => handleAdditionalFeeChange(fee.id, 'name', e.target.value)}
                style={styles.serviceInput} 
              />
              <label style={styles.label}>Amount:</label>
              <input 
                type="number" 
                placeholder="Amount" 
                value={fee.amount}
                onChange={(e) => handleAdditionalFeeChange(fee.id, 'amount', e.target.value)}
                style={styles.serviceInput} 
              />
              <button onClick={() => removeFeeField(fee.id)} style={styles.removeButton}>
                Remove
              </button>
            </div>
          ))}

          <button onClick={addFeeField} style={styles.addButton}>
            Add More Services
          </button>
          <button onClick={handleSave} style={styles.saveButton}>
            Save
          </button>
        </>
      ) : (
        <div style={styles.summaryCard}>
          <h6 style={styles.heading}>Saved Fees for {storeName}</h6> {/* Display store name */}
          {savedFees.map((fee, index) => (
            <div key={index} style={styles.feeItem}>
              <span>{fee.feename}: ₹ {fee.feevalue}</span>
            </div>
          ))}
          <div style={styles.buttonContainer}>
            <button onClick={handleDelete} style={styles.deleteButton}>Delete</button>
          </div>
        </div>
      )}
    </div>
  );
};

// Styles (no changes needed)
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
    padding: '20px',
  },
  heading: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '10px',
    width: '100%',
    maxWidth: '500px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
  },
  additionalFeeContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    width: '100%',
  },
  serviceInput: {
    marginBottom: '5px',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '100%',
  },
  addButton: {
    backgroundColor: 'black',
    color: 'white',
    padding: '10px 30px',
    fontSize: '1rem',
    cursor: 'pointer',
    borderRadius: '100px',
    marginTop: '10px',
  },
  removeButton: {
    marginTop: '10px',
    padding: '8px',
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  saveButton: {
    backgroundColor: 'green',
    color: 'white',
    padding: '10px 30px',
    fontSize: '1rem',
    cursor: 'pointer',
    borderRadius: '100px',
    marginTop: '20px',
  },
  summaryCard: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    width: '100%',
    maxWidth: '500px',
    textAlign: 'center',
  },
  feeItem: {
    marginBottom: '10px',
    fontSize: '16px',
  },
  buttonContainer: {
    marginTop: '20px',
  },
  deleteButton: {
    backgroundColor: 'red',
    color: 'white',
    padding: '10px 30px',
    fontSize: '1rem',
    cursor: 'pointer',
    borderRadius: '100px',
  },
};

export default AddRemoveFee;
