import React, { useState } from 'react';
import axios from 'axios';

const CosmykAccess = () => {
  const [activeTab, setActiveTab] = useState(0); // Track the active tab
  const [categoryName, setCategoryName] = useState('');
  const [categoryImage, setCategoryImage] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleTabClick = (index) => {
    setActiveTab(index); // Update active tab on click
  };

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value); // Update category name
  };

  const handleImageChange = (e) => {
    setCategoryImage(e.target.files[0]); // Update the selected image
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!categoryName || !categoryImage) {
      setError('Both category name and image are required.');
      return;
    }

    const formData = new FormData();
    formData.append('category_name', categoryName);
    formData.append('file', categoryImage);

    try {
      const response = await axios.post('http://192.168.29.80:5001/add-category', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccess(response.data.message); // Set success message
      setCategoryName(''); // Clear input fields
      setCategoryImage(null);
      setError('');
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred while adding the category.');
      setSuccess('');
    }
  };

  return (
    <div style={styles.pageContainer}>
      <h1 style={styles.heading}>Cosmyk Access</h1>

      {/* Tabs */}
      <div style={styles.tabsContainer}>
        <div
          style={styles.tab(activeTab === 0)}
          onClick={() => handleTabClick(0)}
        >
          Add Category
        </div>
        <div
          style={styles.tab(activeTab === 1)}
          onClick={() => handleTabClick(1)}
        >
          Tab 2
        </div>
        <div
          style={styles.tab(activeTab === 2)}
          onClick={() => handleTabClick(2)}
        >
          Tab 3
        </div>
      </div>

      {/* Tab Content */}
      <div style={styles.tabContent}>
        {activeTab === 0 && (
          <div>
            <h3>Add a New Category</h3>
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  value={categoryName}
                  onChange={handleCategoryNameChange}
                  placeholder="Enter category name"
                  style={styles.inputField}
                />
              </div>
              <div>
                <input
                  type="file"
                  onChange={handleImageChange}
                  accept=".png, .jpg, .jpeg"
                  style={styles.inputField}
                />
              </div>
              {error && <p style={styles.errorMessage}>{error}</p>}
              {success && <p style={styles.successMessage}>{success}</p>}
              <button type="submit" style={styles.button}>Add Category</button>
            </form>
          </div>
        )}
        {activeTab === 1 && <div>Content for Tab 2</div>}
        {activeTab === 2 && <div>Content for Tab 3</div>}
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    textAlign: 'center',
    marginTop: '0',
    paddingTop: '0',
    overflow: 'hidden',
  },
  heading: {
    fontSize: '32px',
    color: '#333',
    marginTop: '50px',
  },
  tabsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '15px',
    justifyContent: 'center',
  },
  tab: (isSelected) => ({
    backgroundColor: isSelected ? '#C5CAE9' : '#E8EAF6',
    padding: '8px 15px',
    borderRadius: '5px',
    margin: '5px',
    cursor: 'pointer',
    border: isSelected ? '2px solid #3f51b5' : 'none',
    textAlign: 'center',
    flex: '1 0 25%',
    fontSize: '14px',
    transition: 'background-color 0.3s',
  }),
  tabContent: {
    marginTop: '30px',
  },
  inputField: {
    padding: '10px',
    margin: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '250px',
    fontSize: '14px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#3f51b5',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
    fontSize: '16px',
    marginTop: '10px',
  },
  errorMessage: {
    color: 'red',
    fontSize: '14px',
  },
  successMessage: {
    color: 'green',
    fontSize: '14px',
  },
};

export default CosmykAccess;
