import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logotwo from '../cowhite.png'; // Adjust logo import if necessary

const CategoryPage = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); // State to hold search query

  useEffect(() => {
    axios.get('http://192.168.29.80:5001/fetch-categories')
      .then(response => {
        setCategories(response.data.categories); // Store categories data
        setLoading(false); // Stop loading once data is fetched
      })
      .catch(error => {
        setError("There was an error fetching the categories.");
        setLoading(false);
      });
  }, []);

  // Filter categories based on search query
  const filteredCategories = categories.filter(category =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      marginTop: 0, // Remove any default margin at the top
    },
    logo: {
      width: '200px',
      marginTop: '-60px',
    },
    heading: {
      fontSize: '1.25rem', // Adjust font size for the heading
      color: '#333',
      marginTop: '0', // Remove margin from the top of the heading
    },
    searchBarContainer: {
      width: '300px',
      display: 'flex',
      alignItems: 'center',
      marginTop: '20px', // Add some space below the heading
    },
    searchBar: {
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    noCategoryMessage: {
      textAlign: 'center',
      fontSize: '18px',
      color: '#555',
      marginTop: '20px',
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '20px',
      marginTop: '20px',
    },
    imageWrapper: {
      width: '200px',
      height: '200px',
      overflow: 'hidden',
      borderRadius: '8px',
      position: 'relative',
      cursor: 'pointer',
      transition: 'transform 0.2s, box-shadow 0.2s, border-color 0.2s',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      border: '2px solid #ccc',
    },
    imageStyle: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    categoryNameContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '5px',
    },
    categoryName: {
      fontSize: '16px',
      maxWidth: '150px',
      whiteSpace: 'normal',
      overflow: 'hidden',
      wordWrap: 'break-word',
      textAlign: 'center',
    },
    subtitleStyle: {
      fontSize: '0.600rem',
      color: '#666',
      marginTop: '5px',
      textAlign: 'center',
    },
    loadingOverlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      fontSize: '24px',
      zIndex: 9999,
    },
  };

  return (
    <div style={styles.container}>
      <h6 style={styles.heading}>Find Exactly What You’re Looking For</h6>

      {/* Search Bar Below Heading */}
      <div style={styles.searchBarContainer}>
        <input 
          type="text" 
          placeholder="Search by category name..." 
          style={styles.searchBar} 
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} // Update searchQuery state
        />
      </div>

      {loading && (
        <div style={styles.loadingOverlay}>Loading categories...</div>
      )}

      {error && !loading && (
        <div style={styles.noCategoryMessage}>{error}</div>
      )}

      <div style={styles.imageContainer}>
        {filteredCategories.length > 0 ? (
          filteredCategories.map((category) => (
            <div key={category.name}>
              <div style={styles.imageWrapper}>
                <img
                  src={category.image_url || logotwo} // Fallback to a default image
                  alt={category.name}
                  style={styles.imageStyle}
                />
              </div>
              <div style={styles.categoryNameContainer}>
                <div style={styles.categoryName}>{category.name}</div>
              </div>
              <p style={styles.subtitleStyle}>powered by cosmyk.in</p>
            </div>
          ))
        ) : (
          <div style={styles.noCategoryMessage}>No categories available.</div>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;
